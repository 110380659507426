/* src/components/About.css */
.about-page-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-header {
    background-color: #f3f4f6;
  }
  
  .about-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .footer {
    background-color: #f3f4f6;
    color: #4b5563;
  }
  