/* Login.module.css */
.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #d1f8ff, #ffe4f3);
}

.loginBox {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.loginHeading {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.loginSubtext {
  color: #777;
  margin-bottom: 1.5rem;
}

.inputField {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  margin-bottom: 1rem;
}

.errorMessage {
  color: #d9534f;
  margin-bottom: 1rem;
}

.loginButton {
  background: #4caf50;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.footerLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgotPassword {
  color: #555;
  text-decoration: none;
  font-size: 0.9rem;
}

.signupButton {
  background: #00bfff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}
