/* General Page Styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
}

.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Menu Bar */
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #eff7f8 !important;
  border-radius: 50px; /* Makes the borders round */
  border: 2px solid #aeb2ee !important; /* Black border */
}

.menu-item {
  padding: 10px 20px;
  font-weight: bold;
  text-transform: uppercase; /* Makes the text all uppercase */
  color: #000;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.menu-item:hover {
  color: #007bff; /* Change color on hover */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px;
  border-radius: 10%;
}

.site-name {
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
}

.menu-bar ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu-bar li {
  margin: 0 100px;
}

.menu-bar a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}


/* Horizontal Boxes for Images */
.image-boxes-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.image-box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.box-image {
  width: 300px; /* Ensure all images have the same size */
  height: 200px; /* Ensure all images have the same size */
  border: 2px solid #ccc; /* Add a border or frame around images */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: add shadow */
  object-fit: cover; /* Ensure images cover the area without distortion */
  border-radius: 6px; /* Optional: add rounded corners */
}

/* Events Heading */
.events-heading {
  text-align: center;
  margin-top: 40px;
  font-size: 1.8em;
  color: #555;
}

.gallery-image {
  cursor: pointer;
  width: 300px; /* Fixed width for landscape mode */
  height: 200px; /* Fixed height for landscape mode */
  border-radius: 16px;
  object-fit: cover; /* Ensures images fill the frame without distortion */
  transition: transform 0.3s;
}

/* Remove hover enlargement */
.gallery-image:hover {
  transform: none;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  padding: 0;
  background: white;
  border-radius: 8px;
  overflow: auto; /* Allows scrolling when content overflows */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Keeps the image from being distorted */
  border-radius: 8px; /* Optional: Add rounded edges */
}

/* Vision and Mission */
.vision-mission {
  text-align: center;
  padding: 40px 20px;
}

.vision, .mission {
  padding: 20px;
  margin: 20px 0;
  background: linear-gradient(#5d2ab6, #5784) ;
  border-radius: 8px;
}

.vision h2, .mission h2 {
  margin-bottom: 10px;
}

.mission ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.mission ul li {
  margin-bottom: 10px;
}

/* Footer */
.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.social-links {
  margin-bottom: 15px;
}

.social-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-size: 20px;
}

.social-links a:hover {
  text-decoration: underline;
}

.footer-info {
  font-size: 14px;
}

/* Image Gallery */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
  gap: 16px; /* Space between images */
  padding: 16px;
}

.image-gallery img {
  width: 100%; /* Ensures the image fills the grid cell */
  height: 200px; /* Set a fixed height to ensure landscape mode */
  object-fit: cover; /* Scales images correctly without distortion */
  border-radius: 8px; /* Optional: Adds rounded corners */
  cursor: pointer;
}

/* Remove hover zoom effect */
.image-gallery img:hover {
  transform: none;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #f87171; /* Red color */
  border: none;
  padding: 8px;
  border-radius: 50%;
  color: white;
  font-size: 16px;
}

.header-container {
  display: flex; /* Use flexbox to align items in a row */
  align-items: center; /* Vertically center the items */
  justify-content: center; /* Optionally center everything horizontally */
  gap: 15px; /* Add some space between the logo and the heading */
}

.logo-container {
  flex-shrink: 0; /* Prevent the logo from shrinking */
}

.logo {
  width: 150px; /* Adjust the size of the logo */
  height: auto;
}

.main-heading {
  font-size: 24px; /* Adjust heading size */
  text-align: center; /* Center the text of the heading */
}
