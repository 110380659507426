/* Menu Bar */
.menu-bar {
    background-color: #518fd2;
    padding: 10px;
  }
  
  .menu-bar ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-bar ul li {
    flex: 1;
    text-align: center;
  }
  
  .menu-bar ul li a {
    color: white;
    text-decoration: none;
    padding: 10px;
    font-size: 18px;
    display: block;
  }
  
  .menu-bar ul li a:hover {
    background-color: #007bff;
    border-radius: 5px;
  }

  .menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 50px; /* Makes the borders round */
    border: 2px solid #000; /* Black border */
  }
  
  .menu-item {
    padding: 10px 20px;
    font-weight: bold;
    text-transform: uppercase; /* Makes the text all uppercase */
    color: #000;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .menu-item:hover {
    color: #007bff; /* Change color on hover */
  }