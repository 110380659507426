/* DocumentLibrary.css */

.document-library-container {
  padding: 20px;
}



.document-table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.document-table {
  width: 100%;
  border-collapse: collapse;
}

.document-table th,
.document-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.document-table th {
  background-color: #f4f4f4;
}

.file-name {
  display: flex;
  align-items: center;
}

.file-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.document-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.document-table tr:hover {
  background-color: #e0e0e0;
}

.search-bar {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 250px;
}

.document-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.document-item {
  display: flex;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
}

.document-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/* Styling folder and file icons */
.folder-icon,
.file-icon {
  width: 24px; /* Set a consistent size */
  height: 24px;
  margin-right: 10px;
  display: inline-block;
}

.folder-icon img,
.file-icon img {
  width: 100%;
  height: auto;
}

.clickable-folder {
  cursor: pointer;
}


.document-info {
  flex: 1;
}

.document-info h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #555;
}

.document-info p {
  margin: 5px 0 0;
  color: #888;
}

.document-actions {
  display: flex;
  gap: 10px;
}

.document-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button {
  background-color: #007bff;
  color: white;
}

.download-button {
  background-color: #28a745;
  color: white;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.view-button:hover {
  background-color: #0056b3;
}

.download-button:hover {
  background-color: #218838;
}

.delete-button:hover {
  background-color: #c82333;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #f1f1f1;
}
/* Menu Bar */
.menu-bar {
  background-color: #007bff;
  padding: 10px;
}

.menu-bar ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-bar ul li {
  flex: 1;
  text-align: center;
}

.menu-bar ul li a {
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: 18px;
  display: block;
}

.menu-bar ul li a:hover {
  background-color: #007bff;
  border-radius: 5px;
}


.document-library-container {
  padding: 20px;
  background-color: #f9fafb;
}

.document-table-container {
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #f3f4f6;
  font-weight: bold;
  padding: 8px;
  text-align: left;
}

td {
  padding: 8px;
  border-top: 1px solid #e5e7eb;
}

tr:hover {
  background-color: #f1f5f9;
}

img {
  vertical-align: middle;
}.document-library-container {
  padding: 20px;
  background-color: #f9fafb;
}

.document-table-container {
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #f3f4f6;
  font-weight: bold;
  padding: 8px;
  text-align: left;
}

td {
  padding: 8px;
  border-top: 1px solid #e5e7eb;
}

tr:hover {
  background-color: #f1f5f9;
}

img {
  vertical-align: middle;
}
.document-library-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9fafb;
}

.upload-section .select-button,
.upload-section .upload-button {
  margin-right: 10px;
  cursor: pointer;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
}

.upload-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px; /* Adds spacing between buttons */
  padding: 1rem; /* Adds padding around the buttons */
  background-color: #f3f4f6; /* Optional background color */
  border: #0056b3;
}

.select-button {
  background-color: #007bff;
  color: #fff;
}

.upload-button {
  background-color: #28a745;
  color: #fff;
}
.folder-heading {
  color: #2c3e50;
  margin-top: 1rem;
}
.folder-heading {
  cursor: pointer; /* Hand pointer for folder headings */
}

.subfolder-item span {
  cursor: pointer; /* Hand pointer for subfolder names */
}

.file-list {
  list-style: none;
  padding: 0;
}

.file-item {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 5px;
}

.file-item a {
  color: #3498db;
  text-decoration: none;
}

.file-item a:hover {
  text-decoration: underline;
}

.document-library-header {
  background-color: #f0f0f0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.go-back-button {
  margin-bottom: 1rem;
}

/* Horizontal Boxes for Images */
.image-boxes-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.image-box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.box-image {
  width: 300px; /* Ensure all images have the same size */
  height: 200px; /* Ensure all images have the same size */
  border: 2px solid #ccc; /* Add a border or frame around images */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: add shadow */
  object-fit: cover; /* Ensure images cover the area without distortion */
  border-radius: 8px; /* Optional: add rounded corners */
}

.action-buttons {
  display: flex;
  gap: 10px; /* Adds spacing between buttons */
}

.delete-file-button {
  background-color: #2f3d6d; /* Red background for delete */
}

.select-file-button {
  background-color: #6b7280; /* Gray background for select */
}


