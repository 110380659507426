.messages-page-container {
    max-width: 800px;
    margin: 0 auto;
    }
  
  .week-section {
    border: 2px solid #aac4e2;
    padding: 20px;
    border-radius: 8px;
    transition: box-shadow 0.3s;
  }
  
  .week-section:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* General Page Styles */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: Arial, sans-serif;
  }
  
  .home-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  /* Menu Bar */
  .menu-bar {
    background-color: #007bff;
    padding: 10px;
  }
  
  .menu-bar ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-bar ul li {
    flex: 1;
    text-align: center;
  }
  
  .menu-bar ul li a {
    color: white;
    text-decoration: none;
    padding: 10px;
    font-size: 18px;
    display: block;
  }
  
  .menu-bar ul li a:hover {
    background-color: #007bff;
    border-radius: 5px;
  }
  
  /* Scrolling Gallery */
  .scrolling-gallery {
    flex: 1;
    overflow-x: auto;
    display: flex;
    padding: 20px;
    margin: 20px 0;
  }
  
  .gallery-wrapper {
    display: flex;
    gap: 20px;
  }
  
  .gallery-wrapper img {
    max-width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  /* Vision and Mission */
  .vision-mission {
    text-align: center;
    padding: 40px 20px;
  }
  
  .vision, .mission {
    padding: 20px;
    margin: 20px 0;
    background: linear-gradient(#5d2ab6, #5784) ;
    border-radius: 8px;
  }
  
  .vision h2, .mission h2 {
    margin-bottom: 10px;
  }
  
  .mission ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
  }
  
  .mission ul li {
    margin-bottom: 10px;
  }
  
  /* Footer */
  .footer {
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .social-links {
    margin-bottom: 15px;
  }
  
  .social-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
    font-size: 20px;
  }
  
  .social-links a:hover {
    text-decoration: underline;
  }
  
  .footer-info {
    font-size: 14px;
  }