/* Calendar Container */
.rbc-calendar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f5f5f5; /* Light Gray Background */
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Calendar Header */
.rbc-header {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  font-size: 16px !important;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

/* Calendar Toolbar */
.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rbc-toolbar button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.rbc-toolbar button:hover {
  background: #0056b3;
}

/* Calendar Events */
.rbc-event {
  width: 100% !important;
  margin: 0!important;
  background: #ff9800;
  border-radius: 4px;
  color: white;
  padding: 5px;
  font-size: 14px;
  transition: transform 0.2s ease;
}

.rbc-event:hover {
  transform: scale(1.05);
  background: #e68900;
}

/* Today's Date Highlight */
.rbc-today {
  background-color: #e3f2fd !important;
  border: 2px solid #007bff;
}

.rbc-event-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.rbc-day-slot .rbc-event {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
}

/* Past & Future Dates */
.rbc-off-range {
  color: #b0b0b0;
}

.rbc-off-range-bg {
  background: #f0f0f0;
}

/* Day Hover Effect */
.rbc-day-bg:hover {
  background: #e3e3e3;
  transition: background 0.3s ease;
}
.rbc-event-overlaps {
  box-shadow: none;
}

/* Event Continues */
.rbc-event-continues-prior,
.rbc-event-continues-after {
  border-radius: 0;
}
